/* PREVENT BOUNCE SCROLL ON OSX */

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  padding: 0;
  overflow: auto;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

/* Font for entire app */
* {
  font-family: "Sarabun", sans-serif;
}

kbd {
  display: inline-block;
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 11px;
  line-height: 1.4;
  color: #242729;
  text-shadow: 0 1px 0 #fff;
  background-color: #e1e3e5;
  border: 1px solid #adb3b9;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(12, 13, 14, 0.2), 0 0 0 2px #fff inset;
  white-space: nowrap;
}

/* MODIFY STYLE FOR BOOTSTRAP NAV TABS */

.nav-tabs {
  margin-top: 20px;
  font-size: 16px;
}

.nav-tabs > .active {
  font-weight: bold;
}

.tab-content {
  margin-top: 15px;
}

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
  outline: 0;
}

/* App Info */
.app-info {
  font-size: 12px;
}

.app-info td {
  padding-right: 10px;
}

/* Project */

.project-header {
  background-color: #f5f5f5;
  border-bottom: 1px #e3e3e3 solid;
  margin: -40px;
  margin-bottom: -64px;
  padding: 40px 20px 60px 20px;
}

.feed-source-toolbar-formgroup label {
  margin-right: 10px;
}

.feed-source-table {
  width: 100%;
}

.feed-source-table td,
.feed-source-table th {
  padding: 10px;
}

.feed-source-table thead {
  padding-bottom: 0;
  padding-top: 0;
}

.feed-source-table thead h4 {
  margin: 0;
}

.feed-source-table thead .top-row h4 {
  text-align: center;
  font-size: 20px;
}

.feed-source-table thead i {
  margin-bottom: 5px;
  margin-right: 5px;
}

.comparison-column {
  background-color: #cccccc;
}

.feed-version-column {
  background-color: #eeeeee;
}

.feed-source-table-row {
  border: 1px solid #ddd;
}

.feed-source-table-row:last-child {
  border-bottom: 0;
}

td.feed-source-info {
  width: 33%;
}

.feed-source-info h4 {
  font-size: 20px;
  margin: 0;
  padding-bottom: 5px;
}

.feed-source-info h5 {
  font-size: 16px;
  margin: 0;
  padding-bottom: 5px;
}

.feed-source-info i {
  margin-right: 5px;
  margin-bottom: 5px;
}

/* LABELS */
.feedLabelContainer {
  width: 100%;
}
.feedLabelContainer.grid {
  display: grid;
  grid-template-columns: 100%;
}
.feedLabelContainer.large .noLabelsMessage {
  /* Match the margin of the new labels "button" */
  margin: 0 0 10px 0;
}
.feedSourceLabelRow {
  align-items: baseline;
  display: inline-flex;
  width: 100%;
}
.feedLabel {
  align-items: center;
  border-radius: 5px;
  border: 1px solid;
  max-width: 100%;
  min-width: 60px;
  word-break: break-word;
}
.feedLabel.clickable {
  cursor: pointer;
}
.feedLabel.smaller {
  align-items: center;
  justify-content: center;
  padding: 0px 4px;
}
.feedLabel.small.clickable .labelName span {
  /* The checkbox changes the math from above */
  margin-bottom: 2px;
}
.feedLabel .labelName {
  align-items: baseline;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 5px;
  width: 100%;
}
.feedLabel.smaller .labelName {
  align-items: baseline;
  height: 100%;
  padding: 0;
}
.feedLabel.smaller.clickable .labelName {
  align-items: center;
}

.feedLabel .labelName i {
  margin: 0;
  padding-right: 2px;
}
.feedLabel .labelName span {
  margin: 0 0 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.feedLabelWrapper {
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-start;
  padding: 0 5px 2px 0;
}
.feedLabelWrapper.withButtons {
  align-items: baseline;
  justify-content: space-between;
  padding: 5px 0;
}

/* Label Buttons */
.feedLabelWrapper .actionButtons {
  display: flex;
}
.feedLabelWrapper .actionButtons button {
  background: none;
  border: none;
  font-size: 16px;
  opacity: 0.7;
  padding: 0px;
  text-decoration: underline;
}
.feedLabelWrapper .actionButtons button:hover {
  opacity: 1;
}
.feedLabelWrapper .actionButtons input[type="checkbox"] {
  margin: 0 7px 0 0;
}

/* New Label "button" */
.labelActionButton {
  background: none;
  border: none;
  color: #337ab7;
  grid-column: 1 / -1;
  margin: 0 0 10px 0;
  padding: 0;
  text-decoration: none;
}
.labelActionButton:hover {
  text-decoration: underline;
}

/* Feed transformation substitution styling */
.substitution-row td {
  border-top: none;
}

.substitution-row .input-group {
  padding: 0;
}

.substitution-row .form-control,
.substitution-row .input-group > * {
  height: unset;
  padding: 2px;
}

.substitution-row.allow-edit:hover .form-control {
  color: #337ab7;
  cursor: pointer;
}

.substitution-row.inactive .form-control,
.substitution-row.inactive .input-group > * {
  background: none;
  border-color: transparent;
  box-shadow: none;
}

/* Exception date range date picker */
.exception-date-picker .input-group.date .input-group-addon {
  padding: 6px 4px;
}

/* Exception ranges  */

.exception-subtitle {
  text-transform: uppercase;
  font-size: 80%;
  opacity: 0.7;
}

/* Feed version status styling */
.feed-status {
  border-radius: 5px;
  color: #fff;
  padding: 5px;
}

.status-active {
  background-color: #59b542;
}

.status-expiring-20 {
  background-color: #ada21f;
}

.status-expiring-5 {
  background-color: #c44c35;
}

.status-expired {
  background-color: #000;
}

.status-future {
  background-color: #4366b8;
}

.status-no-version {
  background-color: #853be5;
}

.feed-status-subtext {
  font-size: 12px;
  margin-top: 8px;
}

/* feed validation error types */

.gtfs-error-HIGH-label {
  color: #c9302c;
  background-color: #ffffff;
  border: 1px #c9302c solid;
}

.gtfs-error-MEDIUM-label {
  color: #d08600;
  background-color: #ffffff;
  border: 1px #d08600 solid;
}

.gtfs-error-LOW-label {
  color: #757575;
  background-color: #ffffff;
  border: 1px #757575 solid;
}

.gtfs-error-UNKNOWN-label {
  color: #000000;
  background-color: #ffffff;
  border: 1px #000000 solid;
}

.gtfs-error-HIGH-label-solid {
  background-color: #c9302c;
  color: #ffffff;
}

.gtfs-error-MEDIUM-label-solid {
  background-color: #d08600;
  color: #ffffff;
}

.gtfs-error-LOW-label-solid {
  background-color: #757575;
  color: #ffffff;
}

.gtfs-error-UNKNOWN-label-solid {
  background-color: #000000;
  color: #ffffff;
}

.gtfs-error-HIGH {
  color: #c9302c;
}

.gtfs-error-MEDIUM {
  color: #d08600;
}

.gtfs-error-LOW {
  color: #757575;
}

.gtfs-error-UNKNOWN {
  color: #000000;
}

.error-details-list li {
  margin-bottom: 5px;
}

.text-light {
  color: #a9a9a9;
}

/* project deployments */

.pinned-deployment {
  background-color: #ffdf86 !important;
}

.deployment-na {
  color: #969595;
}

.buffer-right {
  margin-right: 5px;
}

.custom-file {
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 8px;
}

.custom-file .btn-group {
  margin-bottom: 8px;
}

/* Prevent text from being selectable */
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* EDITOR ENTITY LIST */

.entity-list-row {
  /*background-color: '#f5f5f5';*/
  outline: "none";
}

.EntityList input:focus,
textarea:focus {
  outline: none;
}

/* Route List */
.route-list .list-group-item:first-child {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border: 1px solid #ddd !important;
}

.route-list .list-group-item {
  border: 1px solid #ddd !important;
}

.route-list .list-group-item:last-child {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border: 1px solid #ddd !important;
}

/* EDITOR ENTITY DETAILS */

.entity-details {
  height: 100%;
}

.entity-details-header {
  height: 15%;
  min-height: 105px;
}

.entity-details-heading {
  width: 100%;
  margin-bottom: 0px;
}

.entity-details-title {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-top: 6px;
}

.entity-details-body {
  height: 83%;
  overflow-y: scroll;
  width: 102%;
}

/* EDITOR TRIP PATTERN STYLES */

.trip-pattern-controls {
  padding-right: 5px;
  margin-bottom: 5px;
}

.trip-pattern-list {
  height: 90%;
  overflow-y: scroll;
}

.pattern-stop-card {
  border: 1px solid #ccc;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #fafffd;
  border-radius: 3px;
}

/* EDITOR SCROLL STYLES */

/* width */
.GtfsEditor ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Handle */
.GtfsEditor ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}
/* Handle on hover */
.GtfsEditor ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* IMPORTANT (!) for date picker to show up aligned correctly with form input */
.bootstrap-datetimepicker-widget.dropdown-menu.bottom {
  top: 65px !important;
  right: 23px !important;
}

/* UTILITY classes */

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}

.overflow {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  /* NOTE: element must have a width defined in inline css */
  white-space: nowrap;
}

/* EDITOR MAP */

.bus-stop-icon:hover {
  color: yellow;
}

.bus-stop-icon-bg:hover {
  color: yellow;
}
.bus-stop-icon-bg:active {
  color: blue;
}

.bus-stop-icon-fg:hover {
  color: yellow;
}

/*Sticky footer styles*/

.container .text-muted {
  margin: 20px 0;
}

.landing-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: #f5f5f5;
}

.manager-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: 40px;
  height: 100px;
  background-color: #f5f5f5;
}

/* ICON HELPER CLASSES */
.icon-link {
  color: #777;
  margin-right: 10px;
}

.buffer-icon {
  margin-right: 10px;
}

/* SIDEBAR */
.Sidebar {
}

.Sidebar > nav {
  height: 100%;
  position: fixed;
  border-radius: 0;
}

.SidebarNavItemImage {
  width: 20;
  height: 20;
  text-align: center;
  float: left;
}

.SidebarNavItemIcon {
  width: 30;
  height: 30;
  text-align: center;
  float: left;
}

.Sidebar .sidebar-nav-label {
  font-weight: bold;
  margin-top: 3;
  font-size: 12;
  margin-left: 30;
}

.Sidebar .TopNav {
  position: absolute;
  top: 60px;
}

.Sidebar .BottomNav {
  position: absolute;
  bottom: 10px;
}

.Sidebar .LogoContainer {
  position: fixed;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.Sidebar .LogoContainer > .Logo {
  float: left;
  width: 30px;
  height: 30px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 120%;
  background-size: contain;
}

.Sidebar .LogoContainer > .LogoLabel {
  margin-left: 40px;
  margin-top: 6px;
  line-height: 95%;
  color: #bbb;
  font-size: 13px;
  font-weight: bold;
}

/* React Bootstrap Table adjustments (no way to change toolbar class otherwise) */

.react-bs-table-tool-bar {
  margin: 0px;
}

/* Ensure react-select menu is on top of leaflet */
.Select > .Select-menu-outer {
  z-index: 2000000;
}

.loading-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

/* TIMETABLE EDITOR */

.editable-cell {
  cursor: default;
  font-weight: 400;
  white-space: nowrap;
}

/* remove blue focus outline from timetable cell */
.editable-cell:focus {
  outline: 0;
}

.editable-cell .cell-input {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  height: 100%;
  width: 100%;
  margin: 1px;
  margin-left: 2px;
  outline: none;
  padding: 1px;
}

.editable-cell .cell-div {
  display: inline-block;
  height: 100%;
}

/* Bootstrap button dropdown fix for menu showing up underneath leaflet zoom controls */
.dropdown.open.btn-group > ul.dropdown-menu {
  z-index: 20000;
}

/* Line splitter for trip pattern editor sections */
h4.line {
  font-size: 13px;
  position: relative;
  overflow: hidden;
  text-align: left;
}

h4.line:after {
  display: inline-block;
  content: "";
  height: 2px;
  background: #eee;
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: 0px;
  margin-left: 10px;
}

.nomargin {
  margin: 0px;
}

.margin-top-15 {
  margin-top: 15px;
}

/* Add error outline to react-select component */
.form-group.has-error > .Select > .Select-control {
  border-color: #a94442;
}

/* Scrollable button menu... requires custom Dropdown.Menu component (not simply DropdownButton) */
.btn-group.dropdown > .dropdown-menu.scrollable-dropdown {
  height: auto;
  max-height: 400px;
  overflow-x: hidden;
  z-index: 99999 !important;
}

.manager-header {
  background-color: #f5f5f5;
  margin: -40px;
  padding-top: 40px;
  margin-bottom: -64px;
  padding-bottom: 60px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom: 1px #e3e3e3 solid;
}

/* GTFS+ Editor */

.gtfsplus-table-button {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gtfs-plus-column-header {
  background: gray;
  border-right: 4px solid white;
  border-bottom: 0px;
  color: white;
}

/* Job Monitor */

.job-monitor .job-list {
  height: 210px;
  overflow-y: auto;
  overflow-x: hidden;
}

.job-monitor ul {
  margin-bottom: 0px;
}

.job-monitor .job-container {
  margin-top: 15px;
}

.job-monitor .job-container-inner {
  margin-left: 25px;
}

.job-monitor .job-status-progress-bar {
  margin-bottom: 2px;
}

.job-monitor .job-status-message {
  font-size: 12px;
  color: darkGray;
}

.job-monitor .job-spinner-div {
  float: left;
}

.job-monitor .close-job-button {
  padding: 0px 5px 0px 0px;
}

/* Validation viewer */
.validation-item {
  font-size: 18px;
  text-align: center;
}

.link-disabled {
  cursor: not-allowed;
}

.link-disabled * {
  pointer-events: none;
}

/* Recent activity */

.recent-activity-comment {
  background-color: #f0f0f0;
  margin-top: 8;
  padding: 10;
  font-size: 13;
  border-radius: 4;
  color: #444;
}

.recent-activity-date {
  color: #999;
  font-size: 11;
  margin-bottom: 2;
}

.recent-activty-container {
  margin-top: 10;
  padding-bottom: 12;
  border-bottom: 1px solid #ddd;
}
.recent-activity-icon {
  float: left;
  font-size: 20;
  color: #bbb;
}
.recent-activity-inner {
  margin-left: 36;
}

/* Custom classes for the DropdownTreeSelect component that
 * override CSS from 'react-dropdown-tree-select/dist/styles.css'.
 * Modified from https://dowjones.github.io/react-dropdown-tree-select/#/story/with-bootstrap-styles.
 * !important tags are used because somehow the tree selector default styles are applied after this stylesheet.
 */

.route-type-select .dropdown {
  display: block;
}

/* Make some styles look like bootstrap. */
.route-type-select .dropdown-trigger {
  background-color: #fff;
  border-color: #ccc !important;
  border-radius: 4px;
  color: #555;
  padding: 4px 6px !important;
  width: 100%;
}

/* Remove border and background of tags
 * (a "tag" is a selected item displayed inside the input portion of the selector). */
.route-type-select .dropdown-trigger .tag {
  background: none !important;
  border: none !important;
}

/* Hide the search box and the mini "delete" button next to the value. */
.route-type-select .dropdown-trigger .tag-item,
.route-type-select .dropdown-trigger .tag-item .search,
.route-type-select .dropdown-trigger .tag-remove {
  display: none;
}

.route-type-select .dropdown-trigger .tag-item:first-of-type {
  display: inherit;
}

/* Position the drop-down arrow. */
.route-type-select .dropdown-trigger.arrow:after {
  border-style: solid;
  height: 0;
  position: absolute;
  right: 8px;
  top: 50%;
  width: 0;
}
.route-type-select .dropdown-trigger.arrow.bottom:after {
  border-color: #999 transparent transparent;
  border-width: 5px 5px 2.5px;
  content: "" !important;
  margin-top: -5px;
}
.route-type-select .dropdown-trigger.arrow.top:after {
  border-color: transparent transparent #999;
  border-width: 2.5px 5px 5px;
  content: "" !important;
  margin-top: -7.5px;
}

/* Adjust position and width of the dropdown content wrt the input itself. */
.route-type-select .dropdown-content {
  margin-top: -4px;
  width: 100%;
}

/* Hide the radio boxes in the tree list. */
.route-type-select .dropdown-content input[type="radio"] {
  display: none;
}

/* Highlight the selected item, using same color as react-select 1.x. */
.route-type-select .dropdown-content .node.checked {
  background-color: #f5faff;
}
.route-type-select .dropdown-content .node:focus,
.route-type-select .dropdown-content .node:hover {
  background-color: #ebf5ff;
}

/* Don't show disabled itens (e.g. because they represent invalid values) */
.route-type-select .dropdown-content .node.disabled {
  display: none;
}

/* Reduce v-space between tree items, adjust font color. */
.route-type-select .dropdown-content .node {
  color: #555;
  padding: 2px;
}

.route-type-select .dropdown-content .node > label {
  /* Use regular font weight on labels rendered by the tree dropdown. */
  font-weight: normal;
  /* Make labels clickable on their entire width on their right... */
  width: 100%;
}

/* ... but disable clicks for the header (not the +/- sign) for the extended routes. */
.route-type-select .dropdown-content .node.tree.extended-values-node > label {
  color: #999;
  pointer-events: none;
}

/* For the plus/minus symbols for the tree view. */
.route-type-select .toggle {
  color: #555;
  font: normal normal normal 12px/1 FontAwesome;
}
.route-type-select .toggle.collapsed::after {
  content: "\f067";
}
.route-type-select .toggle.expanded::after {
  content: "\f068";
}

/* Datatools backend exception in popover overflows the container */
.popover-content {
  overflow-x: scroll;
}
